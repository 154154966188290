import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import {
  Box,
  Divider,
  Stack,
  ExternalLinkIcon,
  Icon,
  variants,
} from '@resident-advisor/design-system'
import { ThemeProvider } from 'styled-components'
import { dark } from 'themes'
import trackingIds from 'tracking/tracking-ids'
import { FullWidthImage } from 'components/generic/full-width'
import Padded, { PaddedStack } from 'components/generic/padded'
import arrayHasData from 'lib/arrayHasData'

import FeatureSwitch from 'components/generic/feature-switch'
import featureSwitches from 'enums/feature-switches'
import LinkButton from 'components/generic/link-button'
import source from 'messages/source'

import buildArtistLinks from 'lib/buildArtistLinks'
import Seo from 'components/generic/seo/Seo'
import RelatedArtists from '../related-artists'
import ArtistDetailPageTemplate from '../ArtistDetailPageTemplate'
import { artistDetailsSubNavPropType } from '../ArtistDetailSubNav'
import ArtistDetailBar from '../artist-detail-bar'
import ArtistUpcomingEvents from '../artist-upcoming-events'
import ArtistStats from '../artist-stats'
import ArtistReviews from '../artist-reviews'
import ArtistPodcastAndExchange from '../artist-podcast-exchange'
import ArtistAbout from '../artist-about'
import ArtistNews from '../artist-news'
import ArtistFeatures from '../artist-features'
import ArtistLabels from '../artist-labels'
import ArtistEventsArchive from '../artist-events-archive'
import { useArtistSEO, ARTIST_PAGE_TYPE } from '../artistSeoUtils'

const ArtistOverviewTab = ({ artist, subNavOptions, upcomingEvents }) => {
  const intl = useIntl()

  return (
    <ArtistDetailPageTemplate
      id={artist.id}
      contentUrl={artist.contentUrl}
      subNavOptions={subNavOptions}
    >
      <Seo
        {...useArtistSEO({
          data: artist,
          pageType: ARTIST_PAGE_TYPE.about,
        })}
      />
      <ThemeProvider theme={dark}>
        <Divider />
        <ArtistDetailBar {...artist} links={buildArtistLinks(artist)} />
      </ThemeProvider>
      <Box data-tracking-id={trackingIds.artistDetailOverview}>
        <Stack bg="grey.10">
          {arrayHasData(upcomingEvents) && (
            <Padded>
              <ArtistUpcomingEvents events={upcomingEvents} />
            </Padded>
          )}
          {artist.coverImage && (
            <FullWidthImage
              src={artist.coverImage}
              alt={`Cover image for ${artist.name}`}
              sizes={['s', 'm', 'l']}
              width="100%"
            />
          )}
        </Stack>
        <PaddedStack>
          <ArtistStats artistId={artist.id} />
        </PaddedStack>
        <PaddedStack bg="grey.80">
          <ArtistAbout artistId={artist.id} />
          <RelatedArtists
            artistId={artist.id}
            LinkComponent={
              <FeatureSwitch name={featureSwitches.enableSelectors}>
                <LinkButton
                  variant={variants.button.secondary}
                  href="/pro/source"
                  target="_blank"
                  px={3}
                >
                  <Icon Component={ExternalLinkIcon} pr={1} />
                  {intl.formatMessage(source.trySource)}
                </LinkButton>
              </FeatureSwitch>
            }
          />
          <ArtistLabels artistId={artist.id} />
        </PaddedStack>
        <PaddedStack>
          <ArtistPodcastAndExchange artistId={artist.id} />
          <ArtistReviews artistId={artist.id} />
        </PaddedStack>
        <PaddedStack bg="grey.80">
          <ArtistNews artistId={artist.id} />
        </PaddedStack>
        <PaddedStack>
          <ArtistFeatures artistId={artist.id} />
        </PaddedStack>
      </Box>
      <PaddedStack bg="grey.10">
        <ArtistEventsArchive artistId={artist.id} />
      </PaddedStack>
    </ArtistDetailPageTemplate>
  )
}

ArtistOverviewTab.propTypes = {
  artist: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    contentUrl: PropTypes.string.isRequired,
    coverImage: PropTypes.string,
    biography: PropTypes.shape({
      blurb: PropTypes.string,
    }),
  }).isRequired,
  upcomingEvents: PropTypes.array,
  subNavOptions: artistDetailsSubNavPropType,
}

export default ArtistOverviewTab
