import PropTypes from 'prop-types'
import { Alignment, Box } from '@resident-advisor/design-system'
import { featureRoute, filmRoute } from 'lib/routes'
import { useMemo } from 'react'
import FeatureCardImage from './FeatureCardImage'
import FeatureCardContent from './FeatureCardContent'

const FeatureCard = ({ images, contentUrl, lazyRootRef, type, ...props }) => {
  const urlFormatter = type === 'FILM' ? filmRoute : featureRoute

  const linkProps = useMemo(
    () => urlFormatter(contentUrl),
    [contentUrl, urlFormatter]
  )

  return (
    <Alignment flexDirection="column">
      <Box mb={3}>
        <FeatureCardImage
          lazyRootRef={lazyRootRef}
          images={images}
          linkProps={linkProps}
        />
      </Box>
      <FeatureCardContent linkProps={linkProps} {...props} />
    </Alignment>
  )
}

FeatureCard.propTypes = {
  author: PropTypes.object,
  blurb: PropTypes.string.isRequired,
  contentUrl: PropTypes.string.isRequired,
  images: PropTypes.array,
  title: PropTypes.string.isRequired,
  series: PropTypes.shape({
    id: PropTypes.string,
    seriesRef: PropTypes.string,
    title: PropTypes.string,
    contentUrl: PropTypes.string,
  }),
  lazyRootRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  type: PropTypes.string,
}

export default FeatureCard
