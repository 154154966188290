import {
  Alignment,
  Tag,
  Text,
  Icon,
  CircleIcon,
  variants,
} from '@resident-advisor/design-system'
import PropTypes from 'prop-types'
import Link from 'components/generic/link'
import DateComponent from 'components/generic/date'

const CardMeta = ({ items, ...props }) =>
  items?.length > 0 && (
    <Alignment alignItems="center" flexWrap="wrap" {...props}>
      {items
        .map((item, index) => {
          if (item.date && item.dateFormat) {
            return (
              <DateMeta key={index} format={item.dateFormat} date={item.date} />
            )
          }

          if (item.text && item.href) {
            return (
              <LinkMeta key={index} href={item.href} as={item.as}>
                {item.text}
              </LinkMeta>
            )
          }

          if (item.text && item.tag) {
            return <Tag key={index}>{item.text}</Tag>
          }

          if (item.text) {
            return <TextMeta key={index}>{item.text}</TextMeta>
          }

          return null
        })
        .filter((item) => item !== null)
        .reduce((acc, cur, index) => [
          acc,
          <LinkSeparator key={`${index}-separator`} />,
          cur,
        ])}
    </Alignment>
  )

const DateMeta = ({ date, format }) => (
  <DateComponent
    uppercase
    variant={variants.text.heading.s}
    color="secondary"
    format={format}
    date={date}
  />
)

DateMeta.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  format: PropTypes.string,
}

const LinkMeta = ({ href, as, children }) => (
  <Link
    uppercase
    variant={variants.text.heading.s}
    color="secondary"
    href={href}
    as={as}
  >
    {children}
  </Link>
)

LinkMeta.propTypes = {
  href: PropTypes.string.isRequired,
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
}

LinkMeta.defaultProps = {
  as: null,
}

const TextMeta = ({ children }) => (
  <Text uppercase variant={variants.text.heading.s} color="secondary">
    {children}
  </Text>
)

TextMeta.propTypes = { children: PropTypes.node.isRequired }

const LinkSeparator = () => (
  <Icon size={8} mx={2} Component={CircleIcon} color="secondary" />
)

CardMeta.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.exact({
        date: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.instanceOf(Date),
        ]),
        dateFormat: PropTypes.string,
      }),
      PropTypes.shape({
        text: PropTypes.string,
        href: PropTypes.string,
        as: PropTypes.string,
      }),
      PropTypes.shape({
        tag: PropTypes.bool,
        text: PropTypes.string,
      }),
    ])
  ),
}

export default CardMeta
