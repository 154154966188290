import PropTypes from 'prop-types'
import featureSwitchesEnum from 'enums/feature-switches'
import { PropsWithChildren } from 'react'
import {
  useFeatureSwitch,
  useFeatureSwitchesContext,
} from 'context/FeatureSwitchesContext'

const FeatureSwitch = ({
  children,
  name,
  fallback,
}: PropsWithChildren<FeatureSwitchProps>) => {
  const { contextReady } = useFeatureSwitchesContext()
  const enabled = useFeatureSwitch(name)

  if (!enabled) {
    return contextReady && fallback ? fallback() : null
  }

  return <>{children}</>
}

FeatureSwitch.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.oneOf(Object.values(featureSwitchesEnum)).isRequired,
  fallback: PropTypes.func,
}

interface FeatureSwitchProps {
  /** Name of the feature switch. Names can be found in enums/feature-switches.js */
  name: string
  /** Render function to execute if the named feature switch is evaluated as off */
  fallback?: () => JSX.Element
}

export default FeatureSwitch
