import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import messages from 'messages/events'
import StatCard, { StatList } from 'components/events/cards/stat'
import { PersonIcon } from '@resident-advisor/design-system'
import isEmpty from 'lodash/isEmpty'
import { generateUrlForAreaAndCountry, getAreaSafeName } from 'lib/utils'
import trackingIds from 'tracking/tracking-ids'
import { eventsByAreaRoute } from 'lib/routes'

const RegionsMostPlayed = ({ regions }) => {
  const { formatMessage } = useIntl()

  if (isEmpty(regions)) {
    return null
  }

  return (
    <StatCard
      Icon={PersonIcon}
      iconLabel={formatMessage(messages.regionsMostPlayed)}
      list
    >
      <StatList
        data-tracking-id={trackingIds.regionsMostPlayed}
        items={regions.map((region) => ({
          text: getRegionName(region),
          ...eventsByAreaRoute(generateUrlForAreaAndCountry('/events', region)),
        }))}
      />
    </StatCard>
  )
}

RegionsMostPlayed.propTypes = {
  regions: PropTypes.array,
}

const getRegionName = (region) => {
  let result = getAreaSafeName(region, region.country)

  if (region.name.toLowerCase() !== 'all') {
    result += `, ${region.country.urlCode}`
  }

  return result
}

export default RegionsMostPlayed
