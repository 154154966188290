import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { SubSection } from '@resident-advisor/design-system'
import QueryResultHandler from 'components/generic/query-result-handler'
import HorizontalList from 'components/generic/horizontal-list'
import messages from 'messages/dict'
import FeatureCard from 'components/editorial/cards/feature'
import GET_ARTIST_FEATURES from './getArtistFeaturesQuery'

const ArtistFeatures = ({ artistId }) => {
  const { loading, error, data } = useQuery(GET_ARTIST_FEATURES, {
    variables: { id: artistId },
  })

  return (
    <QueryResultHandler
      loading={loading}
      error={error}
      data={data}
      dataKey="artist.features"
      markup={ArtistFeaturesMarkup}
    />
  )
}

ArtistFeatures.propTypes = {
  artistId: PropTypes.string.isRequired,
}

const ArtistFeaturesMarkup = ({ data }) => {
  const intl = useIntl()

  return (
    <SubSection.Stacked title={intl.formatMessage(messages.features)}>
      <HorizontalList Card={FeatureCard} mCols={3} items={data} />
    </SubSection.Stacked>
  )
}

ArtistFeaturesMarkup.displayName = 'ArtistFeaturesMarkup'

ArtistFeaturesMarkup.propTypes = {
  data: PropTypes.array.isRequired,
}

export { ArtistFeatures as default, ArtistFeaturesMarkup }
