import { gql } from '@apollo/client'

const GET_ARTIST_NEWS = gql`
  query GET_ARTIST_NEWS($id: ID!) {
    artist(id: $id) {
      id
      news(limit: 3) {
        id
        title
        date
        contentUrl
        blurb
        imageUrl
      }
    }
  }
`

export default GET_ARTIST_NEWS
