import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { SubSection, variants } from '@resident-advisor/design-system'
import QueryResultHandler from 'components/generic/query-result-handler'
import HorizontalList from 'components/generic/horizontal-list'
import messages from 'messages/magazine'
import NewsCard from 'components/editorial/cards/news'
import { useRouter } from 'next/router'
import ViewMoreButton from 'components/generic/view-more-button'
import GET_ARTIST_NEWS from './getArtistNewsQuery'

const ArtistNews = ({ artistId, ...props }) => {
  const { loading, error, data } = useQuery(GET_ARTIST_NEWS, {
    variables: { id: artistId },
  })

  return (
    <QueryResultHandler
      loading={loading}
      error={error}
      data={data}
      dataKey="artist.news"
      markup={ArtistNewsMarkup}
      {...props}
    />
  )
}

ArtistNews.propTypes = {
  artistId: PropTypes.string.isRequired,
}

const ArtistNewsMarkup = ({ data, ...props }) => {
  const { formatMessage } = useIntl()
  const { asPath } = useRouter()

  return (
    <SubSection.Stacked
      title={formatMessage(messages.news)}
      variant={variants.section.secondary}
      {...props}
    >
      <HorizontalList
        Card={NewsCard}
        mCols={3}
        items={data}
        Button={
          <ViewMoreButton href={`${asPath}/news`}>
            {formatMessage(messages.viewMoreNews)}
          </ViewMoreButton>
        }
      />
    </SubSection.Stacked>
  )
}

ArtistNewsMarkup.propTypes = {
  data: PropTypes.array.isRequired,
}

export { ArtistNews as default, ArtistNewsMarkup }
