/* eslint-disable xss/no-mixed-html */
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  discoverArtistsForYourEvent: {
    id: 'Selectors.DiscoverArtistsForYourEvent',
    defaultMessage: 'Discover artists for your event',
  },
  pageNumberOfTotal: {
    id: 'Selectors.PageNumberOfTotal',
    defaultMessage: 'Page {number} of {total}',
  },
  raFollowers: {
    id: 'Selectors.RAFollowers',
    defaultMessage: 'RA Followers',
  },
  upcomingEvents: {
    id: 'Selectors.UpcomingEvents',
    defaultMessage: 'Upcoming Events',
  },
  lessThanNumber: {
    id: 'Selectors.LessThanNumber',
    defaultMessage: 'Less than {number}',
  },
  overNumber: {
    id: 'Selectors.OverNumber',
    defaultMessage: 'Over {number}',
  },
  numberRange: {
    id: 'Selectors.NumberRange',
    defaultMessage: '{from} - {to}',
  },
  totalEvents: {
    id: 'Selectors.TotalEvents',
    defaultMessage: 'Total Events',
  },
  bookingsIncreasePercentage: {
    id: 'Selectors.BookingsIncreasePercentage',
    defaultMessage: 'Bookings increase %',
  },
  bookingsIncreasePercentageWithDuration: {
    id: 'Selectors.BookingIncreasePercentageWithDuration',
    defaultMessage: 'Bookings increase % ({duration})',
  },
  raPickEvent: {
    id: 'Selectors.RAPickEvent',
    defaultMessage: 'RA Pick Event',
  },
  location: {
    id: 'Selectors.Location',
    defaultMessage: 'Location (Based)',
  },
  aBetaProductBy: {
    id: 'Selectors.ABetaProductBy',
    defaultMessage: 'A BETA product by',
  },
  noArtistsFound: {
    id: 'Selectors.NoArtistsFound',
    defaultMessage: 'No artists found',
  },
  noResultsDescription: {
    id: 'Selectors.NoResultsDescription',
    defaultMessage:
      "Sorry we couldn't find any results that match your criteria.<break></break>Please try using fewer filters or a different search term.",
  },
  noTouringInformationEventsFound: {
    id: 'Selectors.NoTouringInformationEventsFound',
    defaultMessage: 'No events found',
  },
  noTouringInformationEventsFoundDescription: {
    id: 'Selectors.NoTouringInformationEventsFoundDescription',
    defaultMessage:
      "Sorry we couldn't find any results that match your criteria.<break></break>Please try searching for a different location.",
  },
  resultsErrorDescription: {
    id: 'Selectors.ResultsErrorDescription',
    defaultMessage:
      "We're encountering difficulties fetching results at the moment.<break></break>We apologise for any inconvenience caused. Please try again later.",
  },
  searchForAnArtist: {
    id: 'Selectors.SearchForAnArtist',
    defaultMessage: 'Search for an artist',
  },
  trySource: {
    id: 'Source.TrySource',
    defaultMessage: 'Try SOURCE',
  },
  visitSource: {
    id: 'Source.VisitSource',
    defaultMessage: 'Visit SOURCE',
  },
  aBETAProductBy: {
    id: 'Dict.ABETAProductBy',
    defaultMessage: 'A BETA product by',
  },
  touringInformation: {
    id: 'Selectors.TouringInformation',
    defaultMessage: 'Touring information',
  },
  thereAreNoUpcomingEventsForThisArtist: {
    id: 'Selectors.ThereAreNoUpcomingEventsForThisArtist',
    defaultMessage: 'There are no upcoming events for this artist.',
  },
  thereAreNoPastEventsForThisArtist: {
    id: 'Selectors.ThereAreNoPastEventsForThisArtist',
    defaultMessage: 'There are no past events for this artist.',
  },
  thisArtistDoesNotHaveABiography: {
    id: 'Selectors.ThisArtistDoesNotHaveABiography',
    defaultMessage: 'This artist does not have a biography.',
  },
  relatedArtists: {
    id: 'Selectors.RelatedArtists',
    defaultMessage: 'Related Artists',
  },
  relatedArtistsInfo: {
    id: 'Selectors.RelatedArtistsInfo',
    defaultMessage:
      'This section is determined by which artists perform together most frequently.',
  },
  browseFiltersHeading: {
    id: 'Source.BrowseFiltersHeading',
    defaultMessage: 'Filter by',
    description:
      'Heading used for search filters on Selectors, e.g. whether the artist has an RA podcast',
  },
  resetSearch: {
    id: 'Selectors.ResetSearch',
    defaultMessage: 'Reset search',
  },
  upcomingEventsHighFirst: {
    id: 'Selectors.UpcomingEventsHighFirst',
    defaultMessage: 'Upcoming events: High first',
    description:
      'Label for sorting Selectors results by upcoming events, with the highest number of events first',
  },
  upcomingEventsLowFirst: {
    id: 'Selectors.UpcomingEventsLowFirst',
    defaultMessage: 'Upcoming events: Low first',
    description:
      'Label for sorting Selectors results by upcoming events, with the lowest number of events first',
  },
  totalEventsHighFirst: {
    id: 'Selectors.TotalEventsHighFirst',
    defaultMessage: 'Total events: High first',
    description:
      'Label for sorting Selectors results by total events, with the highest number of events first',
  },
  totalEventsLowFirst: {
    id: 'Selectors.TotalEventsLowFirst',
    defaultMessage: 'Total events: Low first',
    description:
      'Label for sorting Selectors results by total events, with the lowest number of events first',
  },
  bookingsIncreasePercentageHighFirst: {
    id: 'Selectors.BookingsIncreasePercentageHighFirst',
    defaultMessage: 'Bookings increase %: High first',
    description:
      'Label for sorting Selectors results by the percentage that their bookings have increased, with the highest increase first',
  },
  bookingsIncreasePercentageLowFirst: {
    id: 'Selectors.BookingsIncreasePercentageLowFirst',
    defaultMessage: 'Bookings increase %: Low first',
    description:
      'Label for sorting Selectors results by the percentage that their bookings have increased, with the lowest increase first',
  },
  alphabeticalAToZ: {
    id: 'Selectors.AToZ',
    defaultMessage: 'Alphabetical: A-Z',
    description: 'Label for sorting Selectors results alphabetically, A-Z',
  },
  alphabeticalZToA: {
    id: 'Selectors.ZToA',
    defaultMessage: 'Alphabetical: Z-A',
    description: 'Label for sorting Selectors results alphabetically, Z-A',
  },
  artistsWithCount: {
    id: 'Selectors.ArtistsWithCount',
    defaultMessage: '{count} {count, plural, one {artist} other {artists}}',
    description:
      'Indicating how many artists there are e.g. "100 artists" or "1 artist"',
  },
  overCountArtists: {
    id: 'Selectors.OverCountArtists',
    defaultMessage: 'Over {count} artists',
  },
  savedArtists: {
    id: 'Selectors.SavedArtists',
    defaultMessage: 'Saved artists',
  },
  tapTheIconOnAnyArtistToSaveThem: {
    id: 'Selectors.TapTheIconOnAnyArtistToSaveThem',
    defaultMessage: 'Tap the <icon></icon> on any artist to save them',
  },
  tapTheIconOnAnyArtistToAddThemToThisList: {
    id: 'Selectors.TapTheIconOnAnyArtistToSaveThem',
    defaultMessage:
      'Use the save button <icon></icon> on any artist to add them to a list.',
  },
  myLists: {
    id: 'Selectors.MyLists',
    defaultMessage: 'My lists',
  },
  myRecentLists: {
    id: 'Selectors.MyRecentLists',
    defaultMessage: 'My recent lists',
  },
  planner: {
    id: 'Selectors.Planner',
    defaultMessage: 'Planner',
  },
  plan: {
    id: 'Selectors.Plan',
    defaultMessage: 'Plan ({artist_count})',
  },
  listsWithCount: {
    id: 'Selectors.ListsWithCount',
    defaultMessage: '{count} {count, plural, one {list} other {lists}}',
    description:
      'Indicating how many lists there are e.g. "100 lists" or "1 list"',
  },
  createList: {
    id: 'Selectors.CreateList',
    defaultMessage: 'Create list',
  },
  listName: {
    id: 'Selectors.ListName',
    defaultMessage: 'List name',
  },
  editList: {
    id: 'Selectors.EditList',
    defaultMessage: 'Edit list',
  },
  save: {
    id: 'Selectors.Save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'Selectors.Cancel',
    defaultMessage: 'Cancel',
  },
  unsaveArtistQuestion: {
    id: 'Selectors.UnsaveArtistQuestion',
    defaultMessage: 'Unsave artist?',
  },
  unsaveArtistDescription: {
    id: 'Selectors.UnsaveArtistDescription',
    defaultMessage:
      'Unsaving this artist will also remove them from all of your lists.',
  },
  searchLocation: {
    id: 'Selectors.SearchLocation',
    defaultMessage: 'Search location',
  },
  bookingHistory: {
    id: 'Source.BookingHistory',
    defaultMessage: 'Booking history',
  },
  noBookingHistory: {
    id: 'Source.NoBookingHistory',
    defaultMessage: 'You have no booking history with this artist.',
  },
  twentyOneAndUnder: {
    id: 'Source.TwentyOneAndUnder',
    defaultMessage: '21 and under',
  },
  between22And25: {
    id: 'Source.Between22And25',
    defaultMessage: '22 to 25',
  },
  between26And29: {
    id: 'Source.Between26And29',
    defaultMessage: '26 to 29',
  },
  between30And39: {
    id: 'Source.Between30And39',
    defaultMessage: '30 to 39',
  },
  fortyAndOver: {
    id: 'Source.FortyAndOver',
    defaultMessage: '40 and over',
  },
  popularBy: {
    id: 'Source.PopularBy',
    defaultMessage: 'Popular by',
  },
  ageRange: {
    id: 'Source.AgeRange',
    defaultMessage: 'Age range',
  },
  twentyOneYearOldsAndUnder: {
    id: 'Source.TwentyOneYearOldsAndUnder',
    defaultMessage: '21 year olds and under',
  },
  twentyTwoToTwentyFiveYearOlds: {
    id: 'Source.TwentyTwoToTwentyFiveYearOlds',
    defaultMessage: '22 to 25 year olds',
  },
  twentySixToTwentyNineYearOlds: {
    id: 'Source.TwentySixToTwentyNineYearOlds',
    defaultMessage: '26 to 29 year olds',
  },
  thirtyToThirtyNineYearOlds: {
    id: 'Source.ThirtyToThirtyNineYearOlds',
    defaultMessage: '30 to 39 year olds',
  },
  fortyYearOldsAndOver: {
    id: 'Source.FortyYearOldsAndOver',
    defaultMessage: '40 year olds and over',
  },
  aboveAverageFollowingBlurb: {
    id: 'Source.AboveAverageFollowingBlurb',
    defaultMessage:
      'This artist has an above-average <bold>RA following</bold> in the listed groups',
  },
  notes: {
    id: 'Source.Notes',
    defaultMessage: 'Notes',
  },
  enterNotesHere: {
    id: 'Source.EnterNotesHere',
    defaultMessage: 'Enter notes here',
  },
  selectStatus: {
    id: 'Source.SelectStatus',
    defaultMessage: 'Select status',
  },
  booked: {
    id: 'Source.Booked',
    defaultMessage: 'Booked',
  },
  contacted: {
    id: 'Source.Contacted',
    defaultMessage: 'Contacted',
  },
  declined: {
    id: 'Source.Declined',
    defaultMessage: 'Declined',
  },
  noReply: {
    id: 'Source.NoReply',
    defaultMessage: 'No reply',
  },
  savingEllipsis: {
    id: 'Source.SavingEllipsis',
    defaultMessage: 'Saving...',
    description: 'Shows when changes are in the process of being saved',
  },
  availability: {
    id: 'Source.Availability',
    defaultMessage: 'Availability',
  },
  noEvent: {
    id: 'Source.NoEvent',
    defaultMessage: 'No Event',
    description: 'Indicates that the artist has no event on RA',
  },
  nearby: {
    id: 'Source.Nearby',
    defaultMessage: 'Nearby',
    description: 'Indicates that the artist is playing nearby',
  },
  playingNearby: {
    id: 'Source.PlayingNearby',
    defaultMessage: 'Playing nearby',
    description: 'Indicates that the artist is playing nearby',
  },
  bookingFeeWithCurrency: {
    id: 'Source.BookingFeeWithCurrency',
    defaultMessage: 'Booking Fee ({currency})',
    description: 'Booking fee for an artist, including the currency symbol',
  },
  agentFeeWithCurrency: {
    id: 'Source.AgentFeeWithCurrency',
    defaultMessage: 'Agent Fee ({currency})',
    description: 'Agent fee for an artist, including the currency symbol',
  },
  travelFeeWithCurrency: {
    id: 'Source.TravelFeeWithCurrency',
    defaultMessage: 'Travel Fee ({currency})',
    description: 'Travel fee for an artist, including the currency symbol',
  },
  bookingFee: {
    id: 'Source.BookingFee',
    defaultMessage: 'Booking Fee',
    description: 'Input a booking fee for the artist',
  },
  travelFee: {
    id: 'Source.TravelFee',
    defaultMessage: 'Travel Fee',
    description: 'Input a travel fee for the artist',
  },
  agentFee: {
    id: 'Source.AgentFee',
    defaultMessage: 'Agent Fee',
    description: 'Input an agent fee for the artist',
  },
  totalFee: {
    id: 'Source.TotalFee',
    defaultMessage: 'Total',
    description: 'Text for the total value of all fee cells',
  },
  randomised: {
    id: 'Source.Randomised',
    defaultMessage: 'Randomised',
    description: 'Label for sorting Source results by random',
  },
  localArtists: {
    id: 'Source.LocalArtists',
    defaultMessage: 'Local artists',
    description: 'Label for suggeest local artists',
  },
  locationBased: {
    id: 'Source.LocationBased',
    defaultMessage: '{location} based',
  },
  discoverArtistsNoEventDate: {
    id: 'Source.DiscoverArtistsNoEventDate',
    defaultMessage: "You haven't entered a date for your event.",
  },
  discoverArtistsBookedWarning: {
    id: 'Source.DiscoverArtistsBookedWarning',
    defaultMessage:
      "Since we don't have a confirmed date for your event yet these artists may already be booked.",
  },
  discoverArtistsWithinDistance: {
    id: 'Source.DiscoverArtistsWithinDistance',
    defaultMessage: 'Visiting artist; Within {distance} kms around your date',
  },
  playingInYourCountry: {
    id: 'Source.PlayingInYourCountry',
    defaultMessage: 'Playing in your country',
  },
  popularWithYourTicketBuyers: {
    id: 'Source.PopularWithYourTicketBuyers',
    defaultMessage: 'Popular with your ticket buyers',
  },
  debutArtists: {
    id: 'Source.DebutArtists',
    defaultMessage: 'Debut in your location',
  },
  debutArtistsBasedOnRAData: {
    id: 'Source.debutArtistsBasedOnRAData',
    defaultMessage: 'Based on RA event data',
  },
  welcomeWithName: {
    id: 'Source.WelcomeWithName',
    defaultMessage: 'Welcome, {name}',
  },
  browseAllArtists: {
    id: 'Source.BrowseAllArtists',
    defaultMessage: 'Browse all artists',
  },
  suggestedArtists: {
    id: 'Source.SuggestedArtists',
    defaultMessage: 'Suggested artists',
  },
  debutInYourLocationForEvent: {
    id: 'Source.DebutInYourLocationForEvent',
    defaultMessage: 'Debut in your location for {event}',
  },
  localArtistsForEvent: {
    id: 'Source.LocalArtistsForEvent',
    defaultMessage: 'Local artists for {event}',
  },
  playingInYourCountryForEvent: {
    id: 'Source.PlayingInYourCountryForEvent',
    defaultMessage: 'Playing in your country for {event}',
  },
  emptyPopularWithYourTicketBuyers: {
    id: 'Source.EmptyPopularWithYourTicketBuyers',
    defaultMessage:
      'To get accurate data you must have sold over {count} tickets on RA. ',
  },
  emptyDebutArtists: {
    id: 'Source.EmptyDebutArtists',
    defaultMessage:
      'Create a list so we can find debut artists in your event location.',
  },
  emptyLocalArtists: {
    id: 'Source.EmptyLocalArtists',
    defaultMessage:
      'Create a list so we can find local artists in your event location.',
  },
  emptyPlayingInYourCountry: {
    id: 'Source.EmptyPlayingInYourCountry',
    defaultMessage:
      'Create a list so we can find artists playing near to your event location.',
  },
  basedIn: {
    id: 'Source.BasedIn',
    defaultMessage: 'Based in',
  },
  homePageInfoPanelTitle: {
    id: 'Source.HomePageInfoPanelTitle',
    defaultMessage: 'Turn event ideas into reality',
  },
  homePageInfoPanelDescription: {
    id: 'Source.HomePageInfoPanelDescription',
    defaultMessage:
      'Take notes, track bookings, and connect with available artists near your event date and location.',
  },
  noSavedArtists: {
    id: 'Source.NoSavedArtists',
    defaultMessage: 'No saved artists',
  },
  useTheSaveIcon: {
    id: 'Source.UseTheSaveIcon',
    defaultMessage:
      'Use the save button on any artist to add them to your planner.',
  },
  seeSuggestedArtists: {
    id: 'Source.SeeSuggestedArtists',
    defaultMessage: 'See suggested artists',
  },
  cantFind: {
    id: 'Source.CantFind',
    defaultMessage: "Can't find what you are looking for?",
  },
})

export default messages
