import { gql } from '@apollo/client'

const ARTIST_BIOGRAPHY_FRAGMENT = gql`
  fragment biographyFields on Artist {
    id
    name
    contentUrl
    image
    biography {
      id
      blurb
      content
      discography
    }
  }
`
const GET_ARTIST_BY_SLUG = gql`
  query GET_ARTIST_BY_SLUG($slug: String!) {
    artist(slug: $slug) {
      id
      name
      followerCount
      firstName
      lastName
      aliases
      isFollowing
      coverImage
      contentUrl
      facebook
      soundcloud
      instagram
      twitter
      bandcamp
      discogs
      website
      urlSafeName
      pronouns
      country {
        id
        name
        urlCode
      }
      residentCountry {
        id
        name
        urlCode
      }
      news(limit: 1) {
        id
      }
      reviews(limit: 1, type: ALLMUSIC) {
        id
      }
      ...biographyFields
    }
  }
  ${ARTIST_BIOGRAPHY_FRAGMENT}
`

export { ARTIST_BIOGRAPHY_FRAGMENT }
export default GET_ARTIST_BY_SLUG
