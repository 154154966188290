import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import messages from 'messages/events'
import { PersonIcon } from '@resident-advisor/design-system'
import StatCard, { StatValue } from 'components/events/cards/stat'
import isEmpty from 'lodash/isEmpty'

const FirstEventYear = ({ firstEvent }) => {
  const { formatMessage } = useIntl()

  if (isEmpty(firstEvent)) {
    return null
  }

  return (
    <StatCard
      Icon={PersonIcon}
      iconLabel={formatMessage(messages.firstEventOnRA)}
    >
      <StatValue>{new Date(firstEvent.date).getFullYear()}</StatValue>
    </StatCard>
  )
}

FirstEventYear.propTypes = {
  firstEvent: PropTypes.shape({
    date: PropTypes.string.isRequired,
  }),
}

export default FirstEventYear
