import PropTypes, { InferProps } from 'prop-types'
import { Box } from '@resident-advisor/design-system'

const VerticalCardGroup = ({
  Heading,
  Button,
  children,
  ...props
}: VerticalCardGroupProps) => (
  <Box {...props}>
    {Heading && <Heading />}

    {children}

    {Button && <Button />}
  </Box>
)

const propTypes = {
  Heading: PropTypes.func,
  Button: PropTypes.func,
  children: PropTypes.node.isRequired,
  pt: PropTypes.object,
}

type VerticalCardGroupProps = InferProps<typeof propTypes>

VerticalCardGroup.propTypes = propTypes

export default VerticalCardGroup
