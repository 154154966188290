import { gql } from '@apollo/client'

const GET_ARTIST_ABOUT = gql`
  query GET_ARTIST_ABOUT($id: ID!) {
    artist(id: $id) {
      id
      bookingDetails
      contentUrl
      biography {
        id
        blurb
      }
    }
  }
`

export default GET_ARTIST_ABOUT
