import { FOLLOW_BUTTON_TYPES } from 'components/shared/follow-button'
import ProfileCard from 'components/events/cards/profile'
import { djBySlugRoute } from 'lib/routes'
import { PROFILE_IMAGE_TYPES } from 'lib/profileImageUtils'

const ArtistCard = (props) => (
  <ProfileCard
    {...props}
    urlFormatter={djBySlugRoute}
    imageType={PROFILE_IMAGE_TYPES.face}
    followButtonType={FOLLOW_BUTTON_TYPES.artist}
  />
)

export default ArtistCard
