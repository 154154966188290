import PropTypes from 'prop-types'
import { Alignment, Box, variants } from '@resident-advisor/design-system'
import ResponsiveImage from 'components/generic/responsive-image'
import CmsContent from 'components/generic/cms-content'
import Link from 'components/generic/link'
import Heading from 'components/generic/heading'
import Date from 'components/generic/date'
import CardMeta from 'components/generic/card-meta'
import ASPECT_RATIO from 'lib/aspectRatio'
import unescape from 'lodash/unescape'
import { newsByIdRoute } from 'lib/routes'

const NewsCard = ({
  imageUrl,
  contentUrl,
  date,
  title,
  blurb,
  isFeatured,
  lazyRootRef,
}) => (
  <Alignment flexDirection="column">
    <Box mb={3}>
      <Link noStyle {...newsByIdRoute(contentUrl)}>
        <ResponsiveImage
          aspect={ASPECT_RATIO['16:9']}
          url={imageUrl}
          alt={title}
          sizes={{ m: '33vw' }}
          lazyRootRef={lazyRootRef}
        />
      </Link>
    </Box>
    {date && <CardMeta items={[{ date, dateFormat: Date.Formats.News }]} />}
    <Heading>
      <Link
        variant={isFeatured ? variants.text.heading.l : variants.text.heading.m}
        href={contentUrl}
      >
        {unescape(title)}
      </Link>
    </Heading>
    <CmsContent color="primary" mt={2} content={blurb} />
  </Alignment>
)

NewsCard.propTypes = {
  imageUrl: PropTypes.string,
  contentUrl: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  blurb: PropTypes.string,
  isFeatured: PropTypes.bool,
  lazyRootRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
}

export default NewsCard
