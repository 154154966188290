import PropTypes from 'prop-types'
import Date from 'components/generic/date'
import CardMeta from 'components/generic/card-meta'
import { featureSeriesRoute, filmSeriesRoute } from 'lib/routes'

const FeatureFilmMeta = ({ series, date }) => {
  const metaItems = [{ date, dateFormat: Date.Formats.DateOnly }]

  if (series?.title && series?.contentUrl) {
    const routeParams = series.contentUrl.startsWith('/features')
      ? featureSeriesRoute(series.contentUrl)
      : filmSeriesRoute(series.contentUrl)
    metaItems.push({ text: series.title, ...routeParams })
  }

  return <CardMeta items={metaItems} />
}

FeatureFilmMeta.propTypes = {
  series: PropTypes.shape({
    title: PropTypes.string,
    contentUrl: PropTypes.string,
  }),
  date: PropTypes.string.isRequired,
}

export default FeatureFilmMeta
