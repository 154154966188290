export { default as ArtistOverviewTab } from './artist-overview-tab'
export {
  default as ArtistEventsTab,
  ArtistEventsRedirects,
  buildPastEventsRedirectUrl,
} from './artist-events-tab'
export { default as ArtistBiographyTab } from './artist-biography-tab'
export { default as ArtistRANewsTab } from './artist-ranews-tab'
export { default as ArtistRAEditorialTab } from './artist-raeditorial-tab'
export { default as ArtistDetailBar } from './artist-detail-bar'
export { default as ArtistTourDatesTab } from './artist-tour-dates-tab'
