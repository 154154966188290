import { gql } from '@apollo/client'

const FOLLOW_BUTTON_TYPES = {
  artist: 'artist',
  label: 'label',
  promoter: 'promoter',
  venue: 'venue',
}

const getMutationForButtonType = ({ id, type, isFollowing, followerCount }) => {
  switch (type) {
    case FOLLOW_BUTTON_TYPES.artist:
      return getArtistMutation(id, isFollowing, followerCount)
    case FOLLOW_BUTTON_TYPES.label:
      return getLabelMutation(id, isFollowing, followerCount)
    case FOLLOW_BUTTON_TYPES.promoter:
      return getPromoterMutation(id, isFollowing, followerCount)
    case FOLLOW_BUTTON_TYPES.venue:
      return getVenueMutation(id, isFollowing, followerCount)
    default:
      return {
        mutation: null,
        optimisticResponse: null,
        variables: null,
      }
  }
}

const constrainCount = (newFollowerCount) => Math.max(0, newFollowerCount)

const getArtistMutation = (id, isFollowing, followerCount) => ({
  mutation: gql`
    mutation toggleFollowArtist($artist: ID!, $shouldFollow: Boolean!) {
      toggleFollowArtist(artist: $artist, shouldFollow: $shouldFollow) {
        id
        isFollowing
        followerCount
      }
    }
  `,
  optimisticResponse: {
    __typename: 'Mutation',
    toggleFollowArtist: {
      id,
      isFollowing: !isFollowing,
      followerCount: isFollowing
        ? constrainCount(followerCount - 1)
        : followerCount + 1,
      __typename: 'Artist',
    },
  },
  variables: {
    artist: id,
    shouldFollow: !isFollowing,
  },
})

const getLabelMutation = (id, isFollowing, followerCount) => ({
  mutation: gql`
    mutation ToggleFollowLabel($labelId: ID!, $shouldFollow: Boolean!) {
      toggleFollowLabel(labelId: $labelId, shouldFollow: $shouldFollow) {
        id
        isFollowing
        followerCount
      }
    }
  `,
  optimisticResponse: {
    __typename: 'Mutation',
    toggleFollowLabel: {
      id,
      isFollowing: !isFollowing,
      followerCount: isFollowing
        ? constrainCount(followerCount - 1)
        : followerCount + 1,
      __typename: 'Label',
    },
  },
  variables: {
    labelId: id,
    shouldFollow: !isFollowing,
  },
})

const getPromoterMutation = (id, isFollowing, followerCount) => ({
  mutation: gql`
    mutation ToggleFollowPromoter($promoterId: ID!, $shouldFollow: Boolean!) {
      toggleFollowPromoter(
        promoterId: $promoterId
        shouldFollow: $shouldFollow
      ) {
        id
        isFollowing
        followerCount
      }
    }
  `,
  optimisticResponse: {
    __typename: 'Mutation',
    toggleFollowPromoter: {
      id,
      isFollowing: !isFollowing,
      followerCount: isFollowing
        ? constrainCount(followerCount - 1)
        : followerCount + 1,
      __typename: 'Promoter',
    },
  },
  variables: {
    promoterId: id,
    shouldFollow: !isFollowing,
  },
})

const getVenueMutation = (id, isFollowing, followerCount) => ({
  mutation: gql`
    mutation ToggleFollowVenue($venueId: ID!, $shouldFollow: Boolean!) {
      toggleFollowVenue(venueId: $venueId, shouldFollow: $shouldFollow) {
        id
        isFollowing
        followerCount
      }
    }
  `,
  optimisticResponse: {
    __typename: 'Mutation',
    toggleFollowVenue: {
      id,
      isFollowing: !isFollowing,
      followerCount: isFollowing
        ? constrainCount(followerCount - 1)
        : followerCount + 1,
      __typename: 'Venue',
    },
  },
  variables: {
    venueId: id,
    shouldFollow: !isFollowing,
  },
})

export { FOLLOW_BUTTON_TYPES }
export default getMutationForButtonType
