import { gql } from '@apollo/client'

const GET_ARTIST_EVENTS_TAB = gql`
  query GET_ARTIST_EVENTS_TAB($slug: String!) {
    artist(slug: $slug) {
      id
      name
      contentUrl
      image
      biography {
        blurb
      }
    }
  }
`

export default GET_ARTIST_EVENTS_TAB
