import PropTypes from 'prop-types'
import {
  Alignment,
  Grid,
  Column,
  Box,
  variants,
} from '@resident-advisor/design-system'
import ResponsiveImage from 'components/generic/responsive-image'
import CmsContent from 'components/generic/cms-content'
import Link from 'components/generic/link'
import Heading from 'components/generic/heading'
import Date from 'components/generic/date'
import CardMeta from 'components/generic/card-meta'
import ASPECT_RATIO from 'lib/aspectRatio'
import unescape from 'lodash/unescape'
import { newsByIdRoute } from 'lib/routes'

const NewsListingCard = ({ date, title, blurb, imageUrl, contentUrl }) => (
  <Box mt={2}>
    <Grid lCols={12} mCols={1} sCols={1} mWrap sWrap>
      <Column lSpan={5}>
        <Link noStyle {...newsByIdRoute(contentUrl)}>
          <ResponsiveImage
            aspect={ASPECT_RATIO['16:9']}
            url={imageUrl}
            alt={`${title} image`}
            sizes={{ m: '42vw' }}
          />
        </Link>
      </Column>
      <Column lSpan={7}>
        <Alignment flexDirection="column">
          <CardMeta items={[{ date, dateFormat: Date.Formats.News }]} />
          <Heading>
            <Link
              variant={variants.text.heading.m}
              {...newsByIdRoute(contentUrl)}
            >
              {unescape(title)}
            </Link>
          </Heading>
          <CmsContent mt={2} content={blurb} />
        </Alignment>
      </Column>
    </Grid>
  </Box>
)

NewsListingCard.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  blurb: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  contentUrl: PropTypes.string.isRequired,
}

export default NewsListingCard
