import PropTypes from 'prop-types'
import { Detail, variants, Column } from '@resident-advisor/design-system'
import Person from 'components/generic/person'

const PersonColumn = ({
  title,
  name,
  imageUrl,
  username,
  contributor,
  ...columnProps
}) => (
  <Column {...columnProps}>
    <Detail title={title}>
      <Person
        textVariant={variants.text.small}
        name={name}
        imageUrl={imageUrl}
        username={username}
        contributor={contributor}
        underline
      />
    </Detail>
  </Column>
)

PersonColumn.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  columnProps: PropTypes.object,
  username: PropTypes.string,
  contributor: PropTypes.bool,
}

export default PersonColumn
