import { gql } from '@apollo/client'

const GET_ARTIST_STATS = gql`
  query GET_ARTIST_STATS($id: ID!) {
    artist(id: $id) {
      id
      firstEvent {
        id
        date
      }
      venuesMostPlayed {
        id
        name
        contentUrl
      }
      regionsMostPlayed {
        id
        name
        urlName
        country {
          id
          name
          urlCode
        }
      }
    }
  }
`

export default GET_ARTIST_STATS
