import { gql } from '@apollo/client'

const GET_ARTIST_EDITORIAL = gql`
  query GET_ARTIST_EDITORIAL($id: ID!) {
    artist(id: $id) {
      id
      name
      followerCount
      firstName
      lastName
      isFollowing
      image
      contentUrl
      facebook
      soundcloud
      instagram
      twitter
      urlSafeName
      image
      biography {
        id
        blurb
      }
      country {
        id
        name
        urlCode
      }
      reviews(limit: 20, type: ALLMUSIC) {
        title
        contentUrl
        imageUrl
        blurb
        date
        recommended
        labels {
          id
          name
          contentUrl
          live
        }
      }
    }
  }
`

export default GET_ARTIST_EDITORIAL
