import { gql } from '@apollo/client'

const GET_RELATED_ARTISTS = gql`
  query GET_RELATED_ARTISTS($id: ID!) {
    artist(id: $id) {
      id
      relatedArtists {
        id
        name
        contentUrl
        isFollowing
        image
        followerCount
      }
    }
  }
`

export default GET_RELATED_ARTISTS
