import PropTypes from 'prop-types'
import { StickyContainer } from 'react-sticky'
import AD_TARGETING_KEYS from 'enums/ad-targeting-keys'
import { useAdTargetingDispatch } from 'hooks/useAdTargeting'
import ArtistDetailSubNav from './ArtistDetailSubNav'

const ArtistDetailPageTemplate = ({
  id,
  contentUrl,
  children,
  subNavFilters,
  subNavOptions,
}) => {
  useAdTargetingDispatch(buildAdTargeting(id, contentUrl))

  return (
    <>
      <StickyContainer>
        <ArtistDetailSubNav
          contentUrl={contentUrl}
          subNavFilters={subNavFilters}
          subNavOptions={subNavOptions}
        />
        {children}
      </StickyContainer>
    </>
  )
}

const buildAdTargeting = (id, path) => [
  [AD_TARGETING_KEYS.PAGE, path],
  [AD_TARGETING_KEYS.CATEGORY, 'Music'],
  [AD_TARGETING_KEYS.SECTION, 'Artists'],
  [AD_TARGETING_KEYS.ARTIST, id.toString()],
]

const artistDetailsSubNavPropType = PropTypes.shape({
  showBiographyTab: PropTypes.bool,
  showNewsTab: PropTypes.bool,
  showEditorialTab: PropTypes.bool,
})

ArtistDetailPageTemplate.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.node,
  contentUrl: PropTypes.string.isRequired,
  subNavFilters: PropTypes.arrayOf(PropTypes.node),
  subNavOptions: artistDetailsSubNavPropType,
}

export default ArtistDetailPageTemplate
