import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import {
  ArtistEventsRedirects,
  ArtistOverviewTab,
  ArtistEventsTab,
  ArtistBiographyTab,
  ArtistRANewsTab,
  ArtistRAEditorialTab,
  ArtistTourDatesTab,
} from 'components/events/pages/artist-detail'
import Redirect from 'components/generic/redirect'
import usePageviewTracking, { PageTypes } from 'hooks/usePageviewTracking'
import GET_ARTIST_BY_SLUG from 'queries/GetArtistBySlugQuery'
import { PROFILE_IMAGE_TYPES } from 'lib/profileImageUtils'
import ProfileHeader from 'components/shared/profile-header'
import {
  useQueryResult,
  DefaultLoadingSpinner,
} from 'components/generic/query-result-handler'
import ENTITY_TYPE from 'enums/entity-type'
import Throw404 from 'components/generic/throw-404'
import Throw500 from 'components/generic/throw-500'
import WithDefaultLayout from 'components/generic/layouts/default'
import arrayHasData from 'lib/arrayHasData'
import { EventsListingType } from 'components/events/sections/filtered-events-listing'
import Tracking, { TRACKING_EVENT } from 'scripts/tracking'
import useUpcomingEvents from 'hooks/useUpcomingEvents'
import Listing from 'components/shared/listing'

const Artists = () => {
  const {
    query: {
      slug: [name, tab],
    },
  } = useRouter()

  const { data, loading, error, empty } = useQueryResult(GET_ARTIST_BY_SLUG, {
    variables: { slug: name },
    dataKey: 'artist',
  })

  if (error) {
    return <Throw500 />
  }

  if (loading) {
    return <DefaultLoadingSpinner />
  }

  if (empty) {
    return <Throw404 entityType={ENTITY_TYPE.Artist} />
  }

  return <ArtistsMarkup data={data} query={{ name, tab }} />
}

const ArtistsMarkup = ({ data, query }) => (
  <>
    <ProfileHeader
      text={data.name}
      backgroundImage={data.image}
      smallImage={data.image}
      smallImageType={PROFILE_IMAGE_TYPES.face}
    />
    <ActiveArtistTab query={query} data={data} />
  </>
)

ArtistsMarkup.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }),
  query: PropTypes.object,
}

const ActiveArtistTab = ({ query: { name, tab }, data }) => {
  usePageviewTracking(PageTypes.Artist, data.id)
  Tracking.useTracking({
    mixpanel: {
      event: TRACKING_EVENT.viewArtistPage,
      properties: {
        'Artist ID': data.id,
        'Artist Name': data.name,
      },
    },
  })

  const { events: upcomingEvents, loading: upcomingEventsLoading } =
    useUpcomingEvents({
      type: Listing.Aggregations.Artist,
      id: data.id,
    })

  const subNavOptions = {
    showBiographyTab: !!data.biography,
    showUpcomingEventsTab: arrayHasData(upcomingEvents),
    upcomingEventsLoading,
    showNewsTab: arrayHasData(data.news),
    showEditorialTab: arrayHasData(data.reviews),
  }

  if (!tab) {
    return (
      <ArtistOverviewTab
        artist={data}
        upcomingEvents={upcomingEvents}
        subNavOptions={subNavOptions}
      />
    )
  }

  switch (tab) {
    case 'events':
      // old nginx permanent redirects will land on 'events', redirect them conditionally to 'tour-dates' or 'past-events'.
      return <ArtistEventsRedirects contentUrl={data.contentUrl} />

    // This is where nginx will currently redirect by default, there might be further redirects depending
    // on the year param or if there are no upcoming events
    case 'tour-dates':
      return (
        <ArtistTourDatesTab
          slug={name}
          contentUrl={data.contentUrl}
          subNavOptions={subNavOptions}
        />
      )

    case 'past-events':
      return (
        <ArtistEventsTab
          slug={name}
          subNavOptions={subNavOptions}
          eventsListingType={EventsListingType.Archive}
        />
      )

    case 'biography':
      return subNavOptions.showBiographyTab ? (
        <ArtistBiographyTab artistId={data.id} subNavOptions={subNavOptions} />
      ) : (
        <Redirect to="/404" />
      )
    case 'news':
      return subNavOptions.showNewsTab ? (
        <ArtistRANewsTab artist={data} subNavOptions={subNavOptions} />
      ) : (
        <Redirect to="/404" />
      )
    case 'editorial':
      return subNavOptions.showEditorialTab ? (
        <ArtistRAEditorialTab
          id={parseInt(data.id)}
          subNavOptions={subNavOptions}
        />
      ) : (
        <Redirect to="/404" />
      )

    default:
      return <Redirect to="/404" />
  }
}

ActiveArtistTab.propTypes = {
  query: PropTypes.shape({
    tab: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    contentUrl: PropTypes.string.isRequired,
    biography: PropTypes.object,
    news: PropTypes.array,
    reviews: PropTypes.array,
  }).isRequired,
}

export default WithDefaultLayout(Artists)
