import { useServerTime } from 'context/ServerTimeContext'
import getYear from 'date-fns/getYear'
import useSeo from 'hooks/useSeo'
import seo from 'messages/seo'
import { useIntl, MessageDescriptor } from 'react-intl'

type MessageInput = {
  message: MessageDescriptor
  values?: Record<string, unknown>
}

const useArtistSEO = ({
  data,
  pageType,
  noIndex = false,
}: {
  data: {
    name: string
    image?: string
    biography?: {
      blurb?: string
    }
  }
  pageType: ARTIST_PAGE_TYPE
  noIndex: boolean
}) => {
  const images = []
  const { getTitle, getSharingTitle, getDescription, getSharingDescription } =
    useSeoMessages()

  const titleMessage = getTitle(data.name, pageType)
  const serverTime = useServerTime()

  if (data.image) {
    images.push({ url: data.image })
  }
  return useSeo({
    titleMessage,
    overrides: {
      description: getDescription(data, pageType),
      ogTitle: getSharingTitle(data.name, pageType),
      ogDescription: getSharingDescription(data, pageType, serverTime),
      images,
      noindex: noIndex,
    },
  })
}

enum ARTIST_PAGE_TYPE {
  about = 'about',
  upcomingEvents = 'upcomingEvents',
  pastEvents = 'pastEvents',
  biography = 'biography',
  news = 'news',
  editorial = 'editorial',
}

const useSeoMessages = () => {
  const intl = useIntl()

  const getTitle = (name: string, pageType: ARTIST_PAGE_TYPE): MessageInput => {
    switch (pageType) {
      case ARTIST_PAGE_TYPE.about:
        return {
          message: seo.aboutTitle,
          values: { name },
        }
      case ARTIST_PAGE_TYPE.upcomingEvents:
        return {
          message: seo.upcomingEventsTitle,
          values: { name },
        }
      case ARTIST_PAGE_TYPE.pastEvents:
        return {
          message: seo.pastEventsTitle,
          values: { name },
        }
      case ARTIST_PAGE_TYPE.biography:
        return {
          message: seo.biographyTitle,
          values: { name },
        }
      case ARTIST_PAGE_TYPE.news:
        return {
          message: seo.newsTitle,
          values: { name },
        }
      case ARTIST_PAGE_TYPE.editorial:
        return {
          message: seo.editorialTitle,
          values: { name },
        }
      default:
        return {
          message: seo.defaultTitle,
          values: { name },
        }
    }
  }

  const getSharingTitle = (name: string, pageType: ARTIST_PAGE_TYPE) => {
    let tabTitle: string

    switch (pageType) {
      case ARTIST_PAGE_TYPE.about:
        tabTitle = intl.formatMessage(seo.aboutTabTitle)
        break
      case ARTIST_PAGE_TYPE.upcomingEvents:
        tabTitle = intl.formatMessage(seo.upcomingEventsTabTitle)
        break
      case ARTIST_PAGE_TYPE.pastEvents:
        tabTitle = intl.formatMessage(seo.pastEventsTabTitle)
        break
      case ARTIST_PAGE_TYPE.biography:
        tabTitle = intl.formatMessage(seo.biographyTabTitle)
        break
      case ARTIST_PAGE_TYPE.news:
        tabTitle = intl.formatMessage(seo.newsTabTitle)
        break
      case ARTIST_PAGE_TYPE.editorial:
        tabTitle = intl.formatMessage(seo.editorialTabTitle)
        break
      default:
        tabTitle = ''
    }

    return tabTitle
      ? intl.formatMessage(seo.defaultTabTitle, { name, tabTitle })
      : intl.formatMessage(seo.defaultTabTitleAlt, { name })
  }

  const getDescription = (
    data: {
      biography?: {
        blurb?: string
      }
      name: string
    },
    pageType: ARTIST_PAGE_TYPE
  ) => {
    const safeBlurb =
      (data?.biography?.blurb && ` ${data?.biography?.blurb}.`) || ''

    switch (pageType) {
      case ARTIST_PAGE_TYPE.about:
        return data?.biography?.blurb || intl.formatMessage(seo.aboutPageType)
      case ARTIST_PAGE_TYPE.upcomingEvents:
        return intl.formatMessage(seo.upcomingEventsPageType, {
          name: data.name,
          blurb: safeBlurb,
        })
      case ARTIST_PAGE_TYPE.pastEvents:
        return intl.formatMessage(seo.pastEventsPageType, {
          name: data.name,
          blurb: safeBlurb,
        })
      case ARTIST_PAGE_TYPE.biography:
        return data?.biography?.blurb || ''
      case ARTIST_PAGE_TYPE.news:
        return intl.formatMessage(seo.newsPageType, {
          name: data.name,
          blurb: safeBlurb,
        })
      case ARTIST_PAGE_TYPE.editorial:
        return intl.formatMessage(seo.editorialPageType, {
          name: data.name,
          blurb: safeBlurb,
        })
      default:
        return intl.formatMessage(seo.defaultPageType)
    }
  }

  const getSharingDescription = (
    data: {
      biography?: {
        blurb?: string
      }
      name: string
    },
    pageType: ARTIST_PAGE_TYPE,
    serverTime: Date
  ) => {
    const year = getYear(serverTime)
    const years = [year, year + 1]
    const formattedYears = years.join(' & ')
    switch (pageType) {
      case ARTIST_PAGE_TYPE.upcomingEvents:
        return intl.formatMessage(seo.upcomingEventsSharingDescription, {
          name: data.name,
          formattedYears,
        })
      case ARTIST_PAGE_TYPE.pastEvents:
        return intl.formatMessage(seo.pastEventsSharingDescription, {
          name: data.name,
        })
      default:
        return (
          data?.biography?.blurb ||
          intl.formatMessage(seo.defaultSharingDescription)
        )
    }
  }
  return {
    getTitle,
    getSharingTitle,
    getDescription,
    getSharingDescription,
  }
}

export { useArtistSEO, useSeoMessages, ARTIST_PAGE_TYPE }
