import PropTypes from 'prop-types'
import { Section, variants } from '@resident-advisor/design-system'
import FilteredNewsListing from 'components/editorial/sections/filtered-news-listing'
import Listing from 'components/shared/listing'
import { djNewsByIdRoute } from 'lib/routes'
import trackingIds from 'tracking/tracking-ids'
import Seo from 'components/generic/seo/Seo'
import ArtistDetailSubNav, {
  artistDetailsSubNavPropType,
} from '../ArtistDetailSubNav'
import { useArtistSEO, ARTIST_PAGE_TYPE } from '../artistSeoUtils'

const ArtistRANewsTab = ({ artist, subNavOptions }) => {
  return (
    <>
      <Seo
        {...useArtistSEO({
          data: artist,
          pageType: ARTIST_PAGE_TYPE.news,
          noIndex: true,
        })}
      />
      <Section variant={variants.section.secondary}>
        <FilteredNewsListing
          id={artist.id}
          baseFilterType={Listing.Aggregations.Artist}
          listingFilterRouteFormatter={djNewsByIdRoute}
          sortOrder={Listing.SortOrder.Descending}
          createSubNav={(filters) => (
            <ArtistDetailSubNav
              subNavFilters={filters}
              contentUrl={artist.contentUrl}
              subNavOptions={subNavOptions}
            />
          )}
          dataTrackingId={trackingIds.newsArtistArchive}
        />
      </Section>
    </>
  )
}

ArtistRANewsTab.propTypes = {
  artist: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    contentUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
    biography: PropTypes.shape({
      blurb: PropTypes.string,
    }),
  }).isRequired,
  subNavOptions: artistDetailsSubNavPropType,
}

export default ArtistRANewsTab
