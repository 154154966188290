import { useQuery } from '@apollo/client'
import { useState } from 'react'
import {
  variants,
  SubSection,
  Grid,
  Text,
  Box,
  Alignment,
  Column,
  Button,
} from '@resident-advisor/design-system'
import { ThemeProvider } from 'styled-components'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import dict from 'messages/dict'
import messages from 'messages/events'
import { light } from 'themes'
import QueryResultHandler from 'components/generic/query-result-handler'
import CmsContent from 'components/generic/cms-content'
import ViewMoreButton from 'components/generic/view-more-button'
import buttonTrackingIds from 'tracking/button-tracking-ids'
import GET_ARTIST_ABOUT from './ArtistAboutQuery'

const ArtistAbout = ({ artistId }) => {
  const { loading, error, data } = useQuery(GET_ARTIST_ABOUT, {
    variables: { id: artistId },
  })

  return (
    <QueryResultHandler
      loading={loading}
      error={error}
      data={data}
      dataKey="artist"
      markup={ArtistAboutMarkup}
    />
  )
}

ArtistAbout.propTypes = {
  artistId: PropTypes.string.isRequired,
}

const ArtistAboutMarkup = ({
  data: { biography, bookingDetails, contentUrl },
}) => {
  const intl = useIntl()

  if (!bookingDetails && !biography) return null

  return (
    <ThemeProvider theme={light}>
      <SubSection.Stacked title={intl.formatMessage(dict.about)}>
        <Grid lCols={12}>
          <Column lSpan={9}>
            {biography && (
              <Box pb={4}>
                <CmsContent pb={3} content={biography.blurb} />
                <ViewMoreButton href={`${contentUrl}/biography`}>
                  {intl.formatMessage(dict.readMore)}
                </ViewMoreButton>
              </Box>
            )}
            {bookingDetails && (
              <ArtistBookingDetails bookingDetails={bookingDetails} />
            )}
          </Column>
        </Grid>
      </SubSection.Stacked>
    </ThemeProvider>
  )
}

ArtistAboutMarkup.propTypes = {
  data: PropTypes.shape({
    biography: PropTypes.shape({
      blurb: PropTypes.string,
    }),
    bookingDetails: PropTypes.string,
    contentUrl: PropTypes.string.isRequired,
  }).isRequired,
}

ArtistAboutMarkup.displayName = 'ArtistAboutMarkup'

const ArtistBookingDetails = ({ bookingDetails }) => {
  const intl = useIntl()
  const [show, setShow] = useState(false)

  return (
    <Alignment flexDirection="column">
      <Text uppercase color="accent" variant={variants.text.heading.m} pb={3}>
        {intl.formatMessage(messages.bookingInformation)}
      </Text>
      {show ? (
        <CmsContent content={bookingDetails} />
      ) : (
        <Box mb={4}>
          <Button
            data-button-tracking-id={buttonTrackingIds.showBookingInfo}
            onClick={() => setShow((x) => !x)}
            variant={variants.button.secondary}
          >
            {intl.formatMessage(messages.showBookingInfo)}
          </Button>
        </Box>
      )}
    </Alignment>
  )
}

ArtistBookingDetails.propTypes = {
  bookingDetails: PropTypes.string.isRequired,
}

ArtistBookingDetails.displayName = 'ArtistBookingDetails'

export { ArtistAbout as default, ArtistAboutMarkup, ArtistBookingDetails }
