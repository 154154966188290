import { useEffect } from 'react'
import { captureError } from 'lib/logger'

export const usePageviewTracking = (type, id) => {
  useEffect(() => {
    const postData = async () => {
      try {
        if (!navigator?.sendBeacon) return

        navigator.sendBeacon(
          `/api/v1/viewcount/record-page-view?pageType=${type}&entityId=${id}`
        )
      } catch (error) {
        captureError(error)
      }
    }

    postData()
  }, [type, id])
}
