import PropTypes from 'prop-types'
import SubNav from 'components/generic/sub-nav'
import trackingIds from 'tracking/tracking-ids'
import { useIntl } from 'react-intl'
import dict from 'messages/dict'
import messages from 'messages/events'
import { djBySlugRoute } from 'lib/routes'

const ArtistDetailSubNav = ({ contentUrl, subNavFilters, subNavOptions }) => {
  const intl = useIntl()
  const subNavItems = createSubNavItems(intl, contentUrl, subNavOptions)

  return (
    <SubNav
      subNavFilters={subNavFilters}
      trackingId={trackingIds.artistDetailSubNav}
      subNavItems={subNavItems}
    />
  )
}

const createSubNavItems = (intl, contentUrl, subNavOptions) => {
  const subNavItems = [
    {
      title: intl.formatMessage(dict.about),
      ...djBySlugRoute(contentUrl),
    },
  ]

  if (subNavOptions?.showUpcomingEventsTab) {
    subNavItems.push({
      title: intl.formatMessage(messages.upcomingEventsSubnavTitle),
      ...djBySlugRoute(`${contentUrl}/tour-dates`),
    })
  }

  subNavItems.push({
    title: intl.formatMessage(messages.pastEventsSubnavTitle),
    ...djBySlugRoute(`${contentUrl}/past-events`),
  })

  if (subNavOptions?.showBiographyTab) {
    subNavItems.push({
      title: intl.formatMessage(messages.biography),
      ...djBySlugRoute(`${contentUrl}/biography`),
    })
  }

  if (subNavOptions?.showNewsTab) {
    subNavItems.push({
      title: intl.formatMessage(dict.raNews),
      ...djBySlugRoute(`${contentUrl}/news`),
    })
  }

  if (subNavOptions?.showEditorialTab) {
    subNavItems.push({
      title: intl.formatMessage(dict.raEditorial),
      ...djBySlugRoute(`${contentUrl}/editorial`),
    })
  }

  return subNavItems
}

const artistDetailsSubNavPropType = PropTypes.shape({
  showBiographyTab: PropTypes.bool,
  showNewsTab: PropTypes.bool,
  showEditorialTab: PropTypes.bool,
  showUpcomingEventsTab: PropTypes.bool,
})

ArtistDetailSubNav.propTypes = {
  contentUrl: PropTypes.string.isRequired,
  subNavFilters: PropTypes.arrayOf(PropTypes.node),
  subNavOptions: artistDetailsSubNavPropType,
}

export { artistDetailsSubNavPropType }
export default ArtistDetailSubNav
