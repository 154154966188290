import PropTypes from 'prop-types'
import { Grid, Column, SubSection } from '@resident-advisor/design-system'
import { ThemeProvider } from 'styled-components'
import { useIntl } from 'react-intl'
import VerticalCardGroup from 'components/generic/vertical-card-group'
import EventListingCard from 'components/events/cards/event-listing'
import messages from 'messages/events'
import { dark } from 'themes'
import ViewMoreButton from 'components/generic/view-more-button'

const EventsArchive = ({ data, linkProps, ...props }) => {
  const { formatMessage } = useIntl()

  return (
    <ThemeProvider theme={dark}>
      <SubSection title={formatMessage(messages.pastEvents)} {...props}>
        <Grid lCols={12} sCols={1} sWrap>
          <Column lSpan={7}>
            <VerticalCardGroup
              Button={() => (
                <ViewMoreButton {...linkProps}>
                  {formatMessage(messages.viewMoreArchive)}
                </ViewMoreButton>
              )}
            >
              {data.map((event) => (
                <EventListingCard key={event.id} event={event} />
              ))}
            </VerticalCardGroup>
          </Column>
        </Grid>
      </SubSection>
    </ThemeProvider>
  )
}

EventsArchive.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      venue: PropTypes.object,
      date: PropTypes.string.isRequired,
      contentUrl: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      artist: PropTypes.string,
    }).isRequired
  ).isRequired,
  linkProps: PropTypes.shape({
    href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
}

export default EventsArchive
