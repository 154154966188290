import { useRef } from 'react'
import PropTypes from 'prop-types'
import { Slide, Alignment } from '@resident-advisor/design-system'
import FullWidth from 'components/generic/full-width'

const HorizontalList = ({
  Card,
  Button,
  items,
  ...gridProps
}: HorizontalListProps) => {
  const slideRef = useRef()

  return (
    <FullWidth sizes={['s']}>
      <Alignment flexDirection="column">
        <Slide {...gridProps} ref={slideRef}>
          {items.map((item, i) => {
            // Slide displays 2 Items on screen initially when in horizontal mode
            // so only pass component ref to items that are off-screen on initial render
            // we also need to take into account the lazy load offset, so we can add 1 to the required index
            // otherwise no ref is passed (document is used instead)
            const lazyRootRef = i >= 3 ? slideRef : undefined
            return (
              <Slide.Item key={item.id || i}>
                <Card lazyRootRef={lazyRootRef} position={i + 1} {...item} />
              </Slide.Item>
            )
          })}
        </Slide>

        {Button}
      </Alignment>
    </FullWidth>
  )
}

const horizontalListPropTypes = {
  Card: PropTypes.func.isRequired,
  Button: PropTypes.object,
  mCols: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  mWrap: PropTypes.bool,
  lWrap: PropTypes.bool,
  rows: PropTypes.number,
}

HorizontalList.propTypes = horizontalListPropTypes

type HorizontalListProps = PropTypes.InferProps<typeof horizontalListPropTypes>

export default HorizontalList
