import { gql } from '@apollo/client'

const GET_ARTIST_REVIEWS = gql`
  query GET_ARTIST_REVIEWS($id: ID!) {
    artist(id: $id) {
      id
      reviews(limit: 4, type: ALLMUSIC) {
        id
        title
        date
        contentUrl
        imageUrl
        blurb
        recommended
        labels {
          id
          name
          contentUrl
          live
        }
      }
    }
  }
`

export default GET_ARTIST_REVIEWS
