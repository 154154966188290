import { gql } from '@apollo/client'

const GET_ARTIST_LABELS = gql`
  query GET_ARTIST_LABELS($id: ID!) {
    artist(id: $id) {
      id
      labels {
        id
        name
        contentUrl
        imageUrl
        isFollowing
        followerCount
      }
    }
  }
`

export default GET_ARTIST_LABELS
