import { gql } from '@apollo/client'

const GET_ARTIST_PODCAST_EXCHANGE = gql`
  query GET_ARTIST_PODCAST_EXCHANGE($id: ID!) {
    artist(id: $id) {
      id
      exchanges(limit: 1) {
        id
        title
        imageUrl
        date
        contentUrl
        blurb
      }
      podcasts(limit: 1) {
        id
        title
        imageUrl
        date
        contentUrl
        translation {
          blurb
        }
      }
    }
  }
`

export default GET_ARTIST_PODCAST_EXCHANGE
