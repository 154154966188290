import { useQuery } from '@apollo/client'
import { useIntl } from 'react-intl'
import { SubSection, Text, variants } from '@resident-advisor/design-system'
import LabelCard from 'components/events/cards/label'
import QueryResultHandler from 'components/generic/query-result-handler'
import Heading from 'components/generic/heading'
import HorizontalList from 'components/generic/horizontal-list'
import PropTypes from 'prop-types'
import messages from 'messages/dict'
import GET_ARTIST_LABELS from './getArtistLabelsQuery'

const ArtistLabels = ({ artistId }) => {
  const { loading, error, data } = useQuery(GET_ARTIST_LABELS, {
    variables: { id: artistId },
  })

  return (
    <QueryResultHandler
      loading={loading}
      error={error}
      data={data}
      dataKey="artist.labels"
      markup={ArtistLabelsMarkup}
    />
  )
}

ArtistLabels.propTypes = {
  artistId: PropTypes.string.isRequired,
}

const ArtistLabelsMarkup = ({ data: labels }) => {
  const intl = useIntl()

  return (
    <SubSection.Stacked>
      <Heading pb={3}>
        <Text uppercase color="accent" variant={variants.text.heading.m}>
          {intl.formatMessage(messages.labels)}
        </Text>
      </Heading>

      <HorizontalList Card={LabelCard} mCols={3} mWrap lWrap items={labels} />
    </SubSection.Stacked>
  )
}

ArtistLabelsMarkup.propTypes = {
  data: PropTypes.array,
}

export { ArtistLabels as default, ArtistLabelsMarkup }
