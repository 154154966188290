import { gql } from '@apollo/client'

const GET_ARTIST_FEATURES_FILMS = gql`
  query GET_ARTIST_FEATURES_FILMS($id: ID!, $type: FeatureType) {
    artist(id: $id) {
      id
      features(limit: 20, type: $type, dateFrom: "2007-01-01") {
        id
        title
        contentUrl
        date
        type
        images {
          imageUrl
          type
        }
        blurb
        author {
          id
          name
          username
          imageUrl
          contributor
        }
      }
    }
  }
`

export default GET_ARTIST_FEATURES_FILMS
