import { Grid, Column, SubSection } from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import { ThemeProvider } from 'styled-components'
import ViewMoreButton from 'components/generic/view-more-button'
import VerticalCardGroup from 'components/generic/vertical-card-group'
import Ad from 'components/generic/ad'
import messages from 'messages/events'
import PropTypes from 'prop-types'
import EventListingCard from 'components/events/cards/event-listing'
import { dark } from 'themes'
import { useRouter } from 'next/router'

const ArtistUpcomingEvents = ({ events }) => {
  const { formatMessage } = useIntl()
  const { asPath } = useRouter()

  return (
    events &&
    !!events.length && (
      <ThemeProvider theme={dark}>
        <SubSection.Stacked title={formatMessage(messages.upcomingEvents)}>
          <Grid lCols={12} sCols={1} sWrap>
            <>
              <Column lSpan={7}>
                <VerticalCardGroup
                  pt={0}
                  Button={() => (
                    <ViewMoreButton href={`${asPath}/tour-dates`}>
                      {formatMessage(messages.viewMoreEvents)}
                    </ViewMoreButton>
                  )}
                >
                  {events.map((event) => (
                    <EventListingCard key={event.id} event={event} />
                  ))}
                </VerticalCardGroup>
              </Column>
              <Column lSpan={5}>
                <Ad
                  variant={Ad.variants.MPU_General_Large}
                  justifyContent="flex-end"
                />
              </Column>
            </>
          </Grid>
        </SubSection.Stacked>
      </ThemeProvider>
    )
  )
}

ArtistUpcomingEvents.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      contentUrl: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      venue: PropTypes.object.isRequired,
      date: PropTypes.string.isRequired,
      pick: PropTypes.object,
      artists: PropTypes.array,
    }).isRequired
  ).isRequired,
}

export default ArtistUpcomingEvents
