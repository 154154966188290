const PageTypes = {
  Review: 'Reviews',
  Artist: 'DJ',
  Event: 'Events',
  Feature: 'Features',
  News: 'News',
}

export { usePageviewTracking as default } from './usePageviewTracking'
export { PageTypes }
