import PropTypes from 'prop-types'
import { Section, Grid, Layout } from '@resident-advisor/design-system'

const DetailBar = ({
  gridProps,
  dataTrackingId,
  children,
  ...sectionProps
}) => (
  <Section py={4} data-tracking-id={dataTrackingId} {...sectionProps}>
    <Layout>
      <Grid {...gridProps}>{children}</Grid>
    </Layout>
  </Section>
)

DetailBar.propTypes = {
  children: PropTypes.node,
  gridProps: PropTypes.object.isRequired,
  dataTrackingId: PropTypes.string.isRequired,
}

export default DetailBar
