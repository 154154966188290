import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import {
  SubSection,
  Grid,
  Box,
  SlashHeader,
  Column,
  variants,
} from '@resident-advisor/design-system'
import { ThemeProvider } from 'styled-components'
import { dark, padding } from 'themes'
import Ad from 'components/generic/ad'
import QueryResultHandler from 'components/generic/query-result-handler'
import CmsContent from 'components/generic/cms-content'
import { useIntl } from 'react-intl'
import messages from 'messages/events'
import Seo from 'components/generic/seo/Seo'
import ArtistDetailPageTemplate from '../ArtistDetailPageTemplate'
import GET_ARTIST_BIOGRAPHY from './ArtistBiographyQuery'
import { artistDetailsSubNavPropType } from '../ArtistDetailSubNav'
import { useArtistSEO, ARTIST_PAGE_TYPE } from '../artistSeoUtils'

const ArtistBiographyTab = ({ artistId, subNavOptions }) => {
  const queryResult = useQuery(GET_ARTIST_BIOGRAPHY, {
    variables: { id: artistId },
  })

  return (
    <QueryResultHandler
      {...queryResult}
      dataKey="artist"
      markup={ArtistBiographyTabMarkup}
      subNavOptions={subNavOptions}
    />
  )
}

ArtistBiographyTab.propTypes = {
  artistId: PropTypes.string.isRequired,
  subNavOptions: artistDetailsSubNavPropType,
}

const ArtistBiographyTabMarkup = ({ data: artist, subNavOptions }) => {
  const intl = useIntl()

  return (
    <ArtistDetailPageTemplate
      id={artist.id}
      contentUrl={artist.contentUrl}
      subNavOptions={subNavOptions}
    >
      <Seo
        {...useArtistSEO({
          data: artist,
          pageType: ARTIST_PAGE_TYPE.biography,
        })}
      />
      <ThemeProvider theme={dark}>
        <SubSection
          variant={variants.section.secondary}
          title={intl.formatMessage(messages.biography)}
          pt={padding.pt}
        >
          <Grid lCols={12} mCols={8} sCols={1} mWrap sWrap>
            <Column lSpan={8} mSpan={8}>
              {artist.biography.blurb && (
                <Box pb={padding.pb}>
                  <CmsContent
                    textVariant={variants.text.heading.l}
                    content={artist.biography.blurb}
                  />
                </Box>
              )}
              {artist.biography.content && (
                <Box pb={padding.pb}>
                  <CmsContent content={artist.biography.content} />
                </Box>
              )}
              {artist.biography.discography && (
                <Box pb={padding.pb}>
                  <SlashHeader>
                    {intl.formatMessage(messages.selectedDiscography)}
                  </SlashHeader>
                  <CmsContent content={artist.biography.discography} />
                </Box>
              )}
            </Column>
            <Column lSpan={4}>
              <Ad
                variant={Ad.variants.MPU_General_Large}
                pb={5}
                justifyContent="flex-end"
              />
            </Column>
          </Grid>
        </SubSection>
      </ThemeProvider>
    </ArtistDetailPageTemplate>
  )
}

ArtistBiographyTabMarkup.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    country: PropTypes.object,
    facebook: PropTypes.string,
    name: PropTypes.string.isRequired,
    twitter: PropTypes.string,
    instagram: PropTypes.string,
    biography: PropTypes.object,
    contentUrl: PropTypes.string.isRequired,
  }).isRequired,
  subNavOptions: artistDetailsSubNavPropType,
}

export { ArtistBiographyTab as default, ArtistBiographyTabMarkup }
