import { useQuery } from '@apollo/client'
import QueryResultHandler from 'components/generic/query-result-handler'
import PropTypes from 'prop-types'
import RelatedArtists from 'components/shared/related-artists'
import GET_RELATED_ARTISTS from './RelatedArtistsQuery'

const RelatedArtistsQuery = ({ artistId, LinkComponent }) => {
  const { loading, error, data } = useQuery(GET_RELATED_ARTISTS, {
    variables: { id: artistId },
  })

  return (
    <QueryResultHandler
      loading={loading}
      error={error}
      data={data}
      dataKey="artist.relatedArtists"
      markup={RelatedArtistsMarkup}
      LinkComponent={LinkComponent}
    />
  )
}

RelatedArtistsQuery.propTypes = {
  artistId: PropTypes.string.isRequired,
  LinkComponent: PropTypes.node,
}

const RelatedArtistsMarkup = ({ data, LinkComponent }) => (
  <RelatedArtists artists={data} LinkComponent={LinkComponent} />
)

RelatedArtistsMarkup.propTypes = {
  data: PropTypes.array,
  LinkComponent: PropTypes.node,
}

export { RelatedArtistsQuery as default, RelatedArtistsMarkup }
