import PropTypes from 'prop-types'
import {
  Detail,
  Text,
  Column,
  Alignment,
  variants,
} from '@resident-advisor/design-system'
import Flag from 'components/shared/flag'
import { useIntl } from 'react-intl'
import dict from 'messages/dict'

const CountryColumn = ({
  country,
  title,
  urlCode,
  urlCodeResident,
  residentCountry,
  ...columnProps
}) => {
  const intl = useIntl()
  if (!country) {
    return null
  }

  return (
    <Column {...columnProps}>
      <Detail title={title}>
        {residentCountry ? (
          <>
            <Alignment align-items="center">
              <Flag size={18} urlCode={country.urlCode} />
              <Text ml={1} color="primary" variant={variants.text.small}>
                {intl.formatMessage(dict.bornLocation, {
                  country: country.name,
                })}
              </Text>
            </Alignment>
            <Alignment align-items="center">
              <Flag size={18} urlCode={residentCountry.urlCode} />
              <Text ml={1} color="primary" variant={variants.text.small}>
                {intl.formatMessage(dict.basedLocation, {
                  residentCountry: residentCountry.name,
                })}
              </Text>
            </Alignment>
          </>
        ) : (
          <Alignment align-items="center">
            <Flag size={18} urlCode={country.urlCode} />
            <Text ml={1} color="primary" variant={variants.text.small}>
              {country.name}
            </Text>
          </Alignment>
        )}
      </Detail>
    </Column>
  )
}

CountryColumn.propTypes = {
  country: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    urlCode: PropTypes.string.isRequired,
  }),
  residentCountry: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    urlCode: PropTypes.string.isRequired,
  }),
  title: PropTypes.string.isRequired,
  columnProps: PropTypes.object,
  urlCode: PropTypes.string,
  urlCodeResident: PropTypes.string,
}

export default CountryColumn
