import PropTypes from 'prop-types'
import { Column, Detail, Text, variants } from '@resident-advisor/design-system'
import styled from 'styled-components'

const TextColumn = ({ text, title, maxLines, ...columnProps }) => {
  if (!text) {
    return null
  }

  return (
    <Column {...columnProps}>
      <Detail title={title}>
        <BreakText
          color="primary"
          variant={variants.text.small}
          maxLines={maxLines}
        >
          {text}
        </BreakText>
      </Detail>
    </Column>
  )
}

const BreakText = styled(Text)`
  word-break: break-word;
`

TextColumn.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
  columnProps: PropTypes.object,
  maxLines: PropTypes.number,
}

export default TextColumn
