const FEATURE_HEADER_IMAGE_TYPES = {
  ALL: 'ALL',
  STANDARD: 'STANDARD',
  LARGE: 'LARGE',
  MOBILE: 'MOBILE',
  VIDEOMP4: 'VIDEOMP4',
  VIDEOWEBM: 'VIDEOWEBM',
}

export default FEATURE_HEADER_IMAGE_TYPES
