import PropTypes from 'prop-types'
import { Text, variants } from '@resident-advisor/design-system'

const StatValue = ({ children, ...props }) => (
  <Text color="primary" variant={variants.text.stat} {...props}>
    {children}
  </Text>
)

StatValue.propTypes = {
  children: PropTypes.node.isRequired,
}

export default StatValue
