import { useMutation } from '@apollo/client'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import {
  Button,
  Icon,
  FollowIcon,
  variants,
} from '@resident-advisor/design-system'
import messages from 'messages/dict'
import { useUserContext } from 'context/UserContext'
import LoginModal from 'components/auth/modals/login/LoginModal'
import buttonTrackingIds from 'tracking/button-tracking-ids'
import { TRACKING_PROPERTY } from 'scripts/tracking'
import getMutationForButtonType, {
  FOLLOW_BUTTON_TYPES,
} from './getMutationForButtonType'

const generateButtonTrackingCode = (isFollowing, type) =>
  `${
    isFollowing ? buttonTrackingIds.unfollow : buttonTrackingIds.follow
  }-${type.toLowerCase()}`

const getVariant = (isFollowing, variant) => {
  if (variant) {
    return variant
  }

  return isFollowing ? variants.button.primary : variants.button.secondary
}

const FollowButton = ({ id, isFollowing, type, variant, followerCount }) => {
  const intl = useIntl()
  const { user } = useUserContext()
  const [showLoginModal, setShow] = useState(false)

  const { mutation, optimisticResponse, variables } = getMutationForButtonType({
    id,
    type,
    isFollowing,
    followerCount,
  })

  const [toggle] = useMutation(mutation)
  const onFollow = () => toggle({ variables, optimisticResponse })

  const buttonTrackingId = generateButtonTrackingCode(isFollowing, type)

  const buttonVariant = getVariant(isFollowing, variant)

  return (
    <>
      <Button
        variant={buttonVariant}
        onClick={() => {
          if (user) return onFollow()
          return setShow(true)
        }}
        px={buttonVariant === variants.button.tertiary ? 0 : 20}
        data-button-tracking-id={buttonTrackingId}
      >
        <Icon flexShrink={0} Component={FollowIcon} />
        {isFollowing
          ? intl.formatMessage(messages.unfollow)
          : intl.formatMessage(messages.follow)}
      </Button>
      {showLoginModal && (
        <LoginModal
          toggleShow={() => setShow(!showLoginModal)}
          onSuccess={() => {
            onFollow()
            setShow(false)
          }}
          source={TRACKING_PROPERTY.loginSource.follow}
        />
      )}
    </>
  )
}

FollowButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isFollowing: PropTypes.bool,
  type: PropTypes.oneOf(Object.keys(FOLLOW_BUTTON_TYPES)).isRequired,
  variant: PropTypes.string,
  followerCount: PropTypes.number,
}

FollowButton.defaultProps = {
  followerCount: 0,
}

export default FollowButton
