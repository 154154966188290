import PropTypes from 'prop-types'
import { PROFILE_IMAGE_TYPES } from 'lib/profileImageUtils'
import { FOLLOW_BUTTON_TYPES } from 'components/shared/follow-button'
import ProfileCard from 'components/events/cards/profile'
import { labelByIdRoute } from 'lib/routes'

const LabelCard = ({ imageUrl, ...props }) => (
  <ProfileCard
    image={imageUrl}
    urlFormatter={labelByIdRoute}
    imageType={PROFILE_IMAGE_TYPES.logo}
    followButtonType={FOLLOW_BUTTON_TYPES.label}
    {...props}
  />
)

LabelCard.propTypes = {
  imageUrl: PropTypes.string.isRequired,
}

export default LabelCard
