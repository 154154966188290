import { gql } from '@apollo/client'

const GET_ARTIST_EVENTS_ARCHIVE = gql`
  query GET_ARTIST_EVENTS_ARCHIVE($id: ID!) {
    artist(id: $id) {
      id
      events(limit: 5, type: PREVIOUS) {
        id
        title
        interestedCount
        isSaved
        isInterested
        date
        contentUrl
        queueItEnabled
        flyerFront
        newEventForm
        images {
          id
          filename
          alt
          type
        }
        pick {
          id
          blurb
        }
        artists {
          id
          name
        }
        venue {
          id
          name
          contentUrl
          live
          area {
            id
            name
            urlName
            country {
              id
              name
              urlCode
            }
          }
        }
      }
    }
  }
`

export default GET_ARTIST_EVENTS_ARCHIVE
