import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import messages from 'messages/music'
import {
  Alignment,
  Box,
  SlashHeader,
  variants,
} from '@resident-advisor/design-system'
import ResponsiveImage from 'components/generic/responsive-image'
import CmsContent from 'components/generic/cms-content'
import Person from 'components/generic/person'
import Heading from 'components/generic/heading'
import Link from 'components/generic/link'
import unescape from 'lodash/unescape'
import { labelByIdRoute } from 'lib/routes'
import CardMeta from 'components/generic/card-meta'
import Date from 'components/generic/date'
import PLAYWRIGHT_TEST_IDS from 'enums/playwright-test-ids'

const MusicCard = ({
  contentTitle,
  imageUrl,
  title,
  blurb,
  author,
  labels,
  Button,
  musicType,
  translation,
  contentUrl,
  urlFormatter,
  lazyRootRef,
  recommended,
  date,
}) => {
  const intl = useIntl()
  const metaItems = []
  if (labels) {
    labels.forEach((label) => {
      const text = label.name
      if (label.live) {
        metaItems.push({ text, ...labelByIdRoute(label.contentUrl) })
      } else {
        metaItems.push({ text })
      }
    })
  }

  if (date) {
    metaItems.push({ date, dateFormat: Date.Formats.Year })
  }

  if (recommended) {
    metaItems.push({ tag: true, text: intl.formatMessage(messages.recommends) })
  }

  return (
    <Alignment
      flexDirection="column"
      data-pw-test-id={PLAYWRIGHT_TEST_IDS.musicCard}
    >
      {contentTitle && <SlashHeader>{contentTitle}</SlashHeader>}
      <Box mb={3}>
        <Link noStyle {...urlFormatter(contentUrl)}>
          <ResponsiveImage
            url={imageUrl}
            alt={`Cover image for ${title}${musicType ? ` ${musicType}` : ''}`}
            sizes={{ m: '25vw' }}
            lazyRootRef={lazyRootRef}
          />
        </Link>
      </Box>
      <CardMeta items={metaItems} />
      <Heading>
        <Link
          color="primary"
          variant={variants.text.heading.m}
          {...urlFormatter(contentUrl)}
        >
          {unescape(title)}
        </Link>
      </Heading>
      {(blurb || translation?.blurb) && (
        <CmsContent
          color="primary"
          mt={2}
          content={blurb || translation?.blurb}
        />
      )}
      {author && (
        <Person
          mt={2}
          name={author.name}
          imageUrl={author.imageUrl}
          username={author.username}
          contributor={author.contributor}
        />
      )}
      {Button}
    </Alignment>
  )
}

MusicCard.propTypes = {
  contentTitle: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  contentUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  blurb: PropTypes.string,
  author: PropTypes.object,
  labels: PropTypes.array,
  Button: PropTypes.node,
  musicType: PropTypes.string,
  translation: PropTypes.shape({
    blurb: PropTypes.string.isRequired,
  }),
  urlFormatter: PropTypes.func.isRequired,
  lazyRootRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  date: PropTypes.string,
  recommended: PropTypes.bool,
}

export default MusicCard
