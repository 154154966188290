import PropTypes from 'prop-types'
import { Detail, Column } from '@resident-advisor/design-system'
import Date from 'components/generic/date'

const DateColumn = ({ title, date, dateFormat, ...columnProps }) => (
  <Column {...columnProps}>
    <Detail title={title}>
      <Date
        date={date}
        color="primary"
        format={dateFormat}
        uppercase={false}
        variant="small"
      />
    </Detail>
  </Column>
)

DateColumn.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
  columnProps: PropTypes.object,
}

export default DateColumn
